
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, inject, ref } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { hideDeviationDelayProposal } from '@/api/deleted-deviation-delay-proposal/hideDeviationDelayProposal'
import { DeviationDelayProposal } from '@/types/deviation-delay-proposal'
import { v4 as uuidv4 } from 'uuid'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'

export default defineComponent({
  name: 'DeviationDelayHideModal',

  setup() {
    const formModal = inject<UseModal<DeviationDelayProposal>>(
      'deviation-delay-hide-modal'
    )
    const { data: deviationDelayProposals } = useDeviationDelayProposal()
    const formRef = ref<QForm | null>(null)
    const loading = ref(false)

    const comment = ref('')

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid || !formModal?.state?.data) return
      loading.value = true

      const body = {
        uuid: uuidv4(),
        advertised: formModal.state.data.advertised,
        departureDate: formModal.state.data.departureDate,
        place: formModal.state.data.delayLocation,
        delayType: formModal.state.data.delayType,
        advertisedTime: formModal.state.data.delayAdvertisedTime,
        comment: comment.value,
      }

      deviationDelayProposals.value = deviationDelayProposals.value.filter(
        (x) =>
          !(
            x.advertised === body.advertised &&
            x.departureDate === body.departureDate &&
            x.delayType === body.delayType &&
            x.delayLocation === body.place &&
            x.delayAdvertisedTime === body.advertisedTime
          )
      )

      await hideDeviationDelayProposal(body)

      loading.value = false
      formModal?.closeModal()
    }

    return {
      formModal,
      formRef,
      onSubmit,
      comment,
      loading,
      requiredRule,
    }
  },
})
