
import { computed, defineComponent, provide, ref, watch } from 'vue'
import DeviationDelayTable from '@/components/deviation/deviation-delay/DeviationDelayTable.vue'
import { useDeviationDelay } from '@/composable/useDeviationDelay'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationDelayFormModal from '@/components/deviation/deviation-delay/DeviationDelayFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { addColumns } from '@/common/deviationAddColumns'
import DeviationDelayHideModal from '@/components/deviation/deviation-delay/DeviationDelayHideModal.vue'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'
import orderBy from 'lodash.orderby'
import { getTracks } from '@/api/lupp/getTracks'
import { LuppTrack } from '@/types/lupp-track'
import { useUserSettings } from '@/composable/useUserSettings'
import { socket } from '@/services/socket'
import { areIntervalsOverlapping } from 'date-fns'

export default defineComponent({
  name: 'DelayDeviations',

  components: {
    DeviationDelayTable,
    DeviationDeleteModal,
    DeviationDelayFormModal,
    DeviationFilterModal,
    DeviationDelayHideModal,
  },

  setup() {
    const { can, currentProject } = useProfile()
    const {
      data: delayDeviations,
      loading: loadingStateDeviationDelay,
      fetchAll,
      period,
    } = useDeviationDelay()
    const {
      fetchAll: fetchAllProposals,
      data: deviationDelayProposals,
      loading: loadingStateDeviationDelayProposal,
    } = useDeviationDelayProposal()
    const deviationDelayHideModal = useModal()
    const deviationDelayFormModal = useModal()
    const deviationCrewDeleteModal = useModal()

    provide('deviation-delay-form-modal', deviationDelayFormModal)
    provide('deviation-delete-modal', deviationCrewDeleteModal)
    provide('deviation-delay-hide-modal', deviationDelayHideModal)

    socket.instance?.on(
      'delay-import-notification',
      (data: { startDate: string; endDate: string; project: string }) => {
        if (data.project !== currentProject.value?.name) return
        const overlapping = areIntervalsOverlapping(
          {
            start: new Date(period.value.from),
            end: new Date(period.value.to),
          },
          { start: new Date(data.startDate), end: new Date(data.endDate) }
        )

        if (!overlapping) return

        fetchAllProposals(period.value)
      }
    )

    const project = currentProject.value?.name as string
    const { settings } = useUserSettings()
    const localstorageBanor = settings.value[project].delay.tracks

    const setBanor = (luppTracks: LuppTrack[]) => {
      const banor = orderBy(
        [...new Set(luppTracks.map((x) => x.description))],
        (x) => x,
        ['asc']
      )
      tracks.value = banor
        .map((label) => {
          const bana = localstorageBanor.find((x) => x.label === label)
          return {
            label,
            visible: bana ? bana.visible : true,
          }
        })
        .filter((x) => x.label !== 'Göteborg – Halmstad')
    }

    const fetchTracks = async () => {
      const { data } = await getTracks()
      setBanor(data)
    }

    fetchTracks()
    fetchAllProposals(period.value)
    fetchAll(period.value)

    const loading = computed(() => {
      return (
        loadingStateDeviationDelayProposal.value.getAll ||
        loadingStateDeviationDelay.value.getAll
      )
    })

    interface TrackItem {
      label: string
      visible: boolean
    }

    const tracksSelected = ref<boolean | null>(false)

    const tracks = ref<TrackItem[]>([])

    watch(
      () => tracks.value,
      (value) => {
        if (value.every((x) => x.visible)) {
          tracksSelected.value = true
        } else if (value.every((x) => !x.visible)) {
          tracksSelected.value = false
        } else {
          tracksSelected.value = null
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const onTrackGroupUpdate = (value: boolean) => {
      tracks.value.forEach((x) => {
        x.visible = value
      })
    }

    const data = computed(() => {
      const delays = orderBy(
        delayDeviations.value,
        ['delayAdvertisedTime'],
        ['desc']
      )
      return [
        ...orderBy(
          deviationDelayProposals.value,
          ['delayPlannedTime'],
          ['desc']
        ),
        ...delays.filter(
          (x) =>
            !x.eventFlags.some((x) => {
              return x.flag === 'AUTO_DELAY'
            })
        ),
        ...delays.filter((x) =>
          x.eventFlags.some((x) => {
            return x.flag === 'AUTO_DELAY'
          })
        ),
      ].filter((x) => {
        return tracks.value.some((t) => t.label === x.bana && t.visible)
      })
    })

    const filterTrackCount = computed(() => {
      return tracks.value.filter((x) => x.visible).length
    })

    watch(
      () => tracks.value,
      (value) => {
        settings.value[project].delay.tracks = value
      }
    )

    return {
      onTrackGroupUpdate,
      tracksSelected,
      tracks,
      addColumns,
      data,
      loading,
      deviationDelayFormModal,
      deviationCrewDeleteModal,
      can,
      deviationDelayHideModal,
      filterTrackCount,
    }
  },
})
