import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'

interface Body {
  uuid: string
  advertised: number
  departureDate: string
  place: string
  delayType: string
  advertisedTime: string
  comment: string
}

export function hideDeviationDelayProposal(
  body: Body
): Promise<AxiosResponse<void>> {
  return instance.post(`/deleted-deviation-delay-proposal`, body)
}
