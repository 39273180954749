import { render } from "./DelayDeviations.vue?vue&type=template&id=4687e72d"
import script from "./DelayDeviations.vue?vue&type=script&lang=ts"
export * from "./DelayDeviations.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QBtn,QBtnDropdown,QBadge,QList,QItemLabel,QSeparator,QItem,QItemSection,QCheckbox});
